// @flow
import * as jscookie from 'js-cookie';

type TrackingFields = {|
  tID: string | void,
  tracking: {
    origin: string,
    medium: string,
    campaign: string,
    audience: string,
    targeting: string,
    pageType: string,
    type: string
  }
|};

const {
  REACT_APP_DEFAULT_TID: defaultTID = '64936805aa6fd',
  REACT_APP_PROMO_TID: promoTID = '5e6a66f54d81c'
} = process.env;

const defaultTracking: TrackingFields = {
  tID: defaultTID,
  tracking: {
    origin: 'Training',
    audience: 'Webpay Registrant',
    medium: 'USCCA.com',
    campaign: 'training',
    targeting: 'Training Class Student',
    pageType: 'Training Class',
    type: 'Class Registration'
  }
};

const promoTracking: TrackingFields = {
  tID: promoTID,
  tracking: {
    origin: 'Promotion',
    audience: 'Webpay Registrant',
    medium: 'USCCA.com',
    campaign: 'Web Purchase',
    targeting: 'Training Class Student',
    pageType: 'Direct Sales',
    type: 'Training Promo'
  }
};

type GetTrackingFieldsParams = {
  isPromo: boolean
};

export const getTrackingFields = ({
  isPromo = false
}: GetTrackingFieldsParams): TrackingFields => {
  let defaults;
  const cookieLeadFields = jscookie.get('uscca_lead_fields');
  const decodedLeadFields = !!cookieLeadFields ? atob(cookieLeadFields) : null;
  switch (true) {
    case isPromo:
      defaults = promoTracking;
      break;
    default:
      defaults = defaultTracking;
  }
  const jsonLeadFields = !!decodedLeadFields
    ? JSON.parse(decodedLeadFields)
    : defaults;
  return jsonLeadFields;
};
