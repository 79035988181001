// @flow
import { CALL_API } from 'redux/middleware/sagas/api';
import type { CallApiReturnType } from 'redux/middleware/sagas/api';
import type { ActionI } from 'redux/interfaces/ActionI';
import type { ErrorI } from 'redux/interfaces/api/ErrorI';
import type { InstructorI } from 'redux/interfaces/api/InstructorI';

import { FETCH, SET, FAIL } from './instructorActions';

// action creators
export type FetchInstructorI = {
  payload: {
    id: string
  }
} & ActionI;

export const fetchInstructor = (id: string): FetchInstructorI => ({
  type: FETCH,
  payload: {
    id
  }
});

export type SetInstructorI = {
  payload: InstructorI,
  meta: {
    receivedAt: number
  }
} & ActionI;

export const setInstructor = (json: InstructorI): SetInstructorI => ({
  type: SET,
  payload: json,
  meta: {
    receivedAt: Date.now()
  }
});

export type FetchInstructorFailureI = {
  payload: Array<ErrorI>,
  error: boolean
} & ActionI;

export const fetchInstructorFailure = (
  errors: Array<ErrorI>
): FetchInstructorFailureI => ({
  type: FAIL,
  payload: errors,
  error: true
});

// Async Actions
export const getInstructor = (id: string): CallApiReturnType => ({
  type: CALL_API,
  payload: {
    method: 'GET',
    endpoint: `/api/training/instructors/${id}`,
    params: [id],
    actions: {
      request: fetchInstructor,
      success: setInstructor,
      failure: fetchInstructorFailure
    },
    isRedirectOnError: true
  }
});
