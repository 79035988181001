// @flow
import type {
  DateI as APIDateI,
  OccurrenceI as APIOccurrenceI
} from 'redux/interfaces/api/OccurrenceI';
import type { EntityRecordI } from './EntityRecord';
import EntityRecord from './EntityRecord';
import { areDatesAreOnSameDay } from 'shared/utils/dates';

export const parseOccurrenceJsonToEntity = (
  occurrenceJson: APIOccurrenceI,
  originalRecord: EntityRecordI
): EntityRecordI => {
  const existingValues = originalRecord.toJS();
  const {
    data: {
      id: occurrenceId,
      attributes: {
        slug: occurrenceSlug,
        capacity,
        isPast,
        numberOfRegistrants,
        dates: datesArr,
        isEligibleForInstructorAutomation,
        registrationQuestions,
        instructors
      }
    }
  } = occurrenceJson;

  const dates = [];
  datesArr.forEach(function ({ startTime, endTime }: APIDateI) {
    const isSingleDay = areDatesAreOnSameDay(
      new Date(startTime),
      new Date(endTime)
    );

    dates.push({
      isSingleDay,
      startTime,
      endTime
    });
  });

  const numberOfOpenSeats = capacity - numberOfRegistrants;

  const record = new EntityRecord({
    ...existingValues,
    occurrenceId,
    occurrenceSlug,
    numberOfOpenSeats,
    isPast,
    dates,
    isEligibleForInstructorAutomation,
    registrationQuestions,
    instructors
  });
  // $FlowFixMe, prop check is unnecessary, as immutable record always returns all props
  return record;
};
