// @flow
import { put, select, take } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';
import { entityInstructorIdSelector } from 'redux/modules/entity/entitySelectors';
import { getInstructor } from 'redux/modules/instructor/instructorActionCreators';

export function* instructorSaga(): Saga<void> {
  for (;;) {
    const previousId = yield select(entityInstructorIdSelector);
    yield take();
    const instructorId = yield select(entityInstructorIdSelector);

    if (instructorId && instructorId !== previousId) {
      yield put(getInstructor(instructorId));
    }
  }
}
