// @flow
import { handleActions } from 'redux-actions';
import validate from './validate';
import {
  NONE,
  LOADING,
  LOADED,
  PENDING,
  SUCCESS,
  ERROR
} from 'shared/constants/status';
import {
  SET_IS_AUTHED,
  FETCH_REQUEST,
  FETCH_SUCCESS,
  FETCH_ERROR,
  CREATE_REQUEST,
  CREATE_SUCCESS,
  CREATE_ERROR,
  UPDATE_PASSWORD_REQUEST,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_ERROR,
  SET_BLURRED,
  SET_VALUE
} from './userActions';
import type {
  ReceiveUserReturn,
  GetUserErrorReturn,
  CreateUserSuccessReturn,
  CreateUserErrorReturn,
  GetPasswordErrorReturn,
  SetValueI,
  SetBlurredI
} from './userActionCreators';
import type { ME } from './userQueries';

export type StateI = {
  isAuthed: boolean,
  isNewUser: boolean,
  getStatus: string,
  getError: ?string,
  createUserStatus: string,
  createUserError: ?string,
  createUserErrorEmailDuplicate: boolean,
  isPasswordReady: boolean,
  updatePasswordStatus: string,
  updatePasswordError: string,
  me: ME,
  values: {
    password: string,
    passwordConfirmation: string
  },
  fields: {
    password: {
      hasBlurred: boolean
    },
    confirmPassword: {
      hasBlurred: boolean
    }
  },
  hasErrors: boolean,
  errors: {
    password?: string,
    passwordConfirmation?: string
  }
};

const initialState = {
  me: {
    id: '',
    name: '',
    surname: '',
    email: '',
    phone: ''
  },
  isAuthed: false,
  isNewUser: false,
  getStatus: NONE,
  getError: null,
  createUserStatus: NONE,
  createUserError: null,
  createUserErrorEmailDuplicate: false,
  isPasswordReady: false,
  updatePasswordStatus: NONE,
  updatePasswordError: '',
  values: {
    password: '',
    passwordConfirmation: ''
  },
  fields: {
    password: {
      hasBlurred: false
    },
    passwordConfirmation: {
      hasBlurred: false
    }
  },
  hasErrors: false,
  errors: {}
};

const user = handleActions(
  {
    [SET_IS_AUTHED]: (state: StateI): StateI => ({
      ...state,
      isAuthed: true
    }),
    [FETCH_REQUEST]: (state: StateI, action): StateI => ({
      ...state,
      getStatus: LOADING
    }),
    [FETCH_SUCCESS]: (state: StateI, action: ReceiveUserReturn): StateI => ({
      ...state,
      isAuthed: true,
      getStatus: LOADED,
      me: action.payload.data.me
    }),
    [FETCH_ERROR]: (state: StateI, action: GetUserErrorReturn): StateI => {
      const [error] = action.error;

      return {
        ...state,
        isAuthed: false,
        getStatus: ERROR,
        getError: error.message
      };
    },
    [CREATE_REQUEST]: (state: StateI): StateI => ({
      ...state,
      createUserStatus: PENDING,
      createUserError: null,
      createUserErrorEmailDuplicate: false
    }),
    [CREATE_SUCCESS]: (
      state: StateI,
      action: CreateUserSuccessReturn
    ): StateI => ({
      ...state,
      isNewUser: true,
      createUserStatus: SUCCESS,
      me: {
        ...state.me,
        id: action.payload.data.createUserIdentity.id
      }
    }),
    [CREATE_ERROR]: (state: StateI, action: CreateUserErrorReturn): StateI => {
      const [error] = action.error;
      // Error: HTTP Error: 409, 409 is for conflict, likely duplicate email inside of the identity api
      const isDuplicate = error.message.split('HTTP Error: 409').length > 1;

      return {
        ...state,
        createUserStatus: ERROR,
        createUserError: error,
        createUserErrorEmailDuplicate: isDuplicate
      };
    },
    [UPDATE_PASSWORD_REQUEST]: (state: StateI): StateI => ({
      ...state,
      updatePasswordStatus: PENDING
    }),
    [UPDATE_PASSWORD_SUCCESS]: (state: StateI): StateI => ({
      ...state,
      updatePasswordStatus: SUCCESS
    }),
    [UPDATE_PASSWORD_ERROR]: (
      state: StateI,
      action: GetPasswordErrorReturn
    ): StateI => {
      const [error] = action.error;
      const errorMessage =
        'Something went wrong. Please try again. If the problem persists, call this number: 800-674-9779.';
      return {
        ...state,
        updatePasswordStatus: ERROR,
        updatePasswordError: errorMessage,
        getError: error.message
      };
    },
    [SET_VALUE]: (
      state: StateI,
      { payload: { field, value } }: SetValueI
    ): StateI => {
      const values = {
        ...state.values,
        [field]: value
      };
      const { password, passwordConfirmation } = values;

      const errors = validate(
        ({
          ...values,
          password,
          passwordConfirmation
        }: any)
      );
      return {
        ...state,
        values,
        hasErrors: Object.keys(errors).length > 0,
        errors,
        isPasswordReady:
          password === passwordConfirmation && passwordConfirmation.length >= 8
      };
    },
    [SET_BLURRED]: (
      state: StateI,
      { payload: { field, hasBlurred } }: SetBlurredI
    ): StateI => ({
      ...state,
      fields: {
        ...state.fields,
        [field]: {
          hasBlurred
        }
      }
    })
  },
  initialState
);

export default user;
