// @flow
import type { InstructorI as APIInstructorI } from 'redux/interfaces/api/InstructorI';
import type { EntityRecordI } from './EntityRecord';
import EntityRecord from './EntityRecord';
import { States } from 'shared/constants/states';

const { REACT_APP_TRAINING_APP_URL: firearmsTrainingUrl = '' } = process.env;

export const parseInstructorJsonToEntity = (
  instructorJson: APIInstructorI,
  originalRecord: EntityRecordI
): EntityRecordI => {
  const existingValues = originalRecord.toJS() || {};
  const stateSlug = States.find(
    (s) => s.abbreviation === existingValues.state
  )?.slug;
  const instructorSlug = instructorJson.data.attributes.slug;

  if (
    existingValues.originatingSiteUrl ||
    !existingValues.occurrenceSlug ||
    !stateSlug ||
    !instructorSlug
  ) {
    return originalRecord;
  }

  const record = new EntityRecord({
    ...existingValues,
    originatingSiteUrl: `${firearmsTrainingUrl}/instructors/${stateSlug}-instructors/${instructorSlug}/${existingValues.occurrenceSlug}/`
  });
  // $FlowFixMe, prop check is unnecessary, as immutable record always returns all props
  return record;
};
