// @flow
import React from 'react';
import type { Node } from 'react';

import styles from './Footer.module.scss';

const Footer = (): Node => (
  <header className={styles.footer}>
    <global-footer site="training"></global-footer>
  </header>
);

Footer.displayName = 'Footer';

export default Footer;
