// @flow
import React, { useEffect } from 'react';
import type { Node } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import Loadable from 'react-loadable';
import { Spinner } from '@blueprintjs/core';
import * as jscookie from 'js-cookie';
import { withLDProvider, useLDClient } from 'launchdarkly-react-client-sdk';
import { loadSegment } from '@delta-defense/frontend-analytics/loadSegment/loadSegment';
import { Analytics } from '@delta-defense/frontend-analytics/analytics/analytics';

import { NOT_FOUND_PATH, REGISTER_PATH } from 'shared/constants/paths';
import DefaultLayout from 'shared/layout/DefaultLayout';
import getLaunchDarklyUser from 'shared/utils/getLaunchDarklyUser';

const launchDarklyId =
  process.env.LAUNCH_DARKLY_ID || '62cc5033305aa31221871713';
const launchDarklyUser = getLaunchDarklyUser();

const Checkout = Loadable({
  loader: () => import('./containers/Checkout/Checkout'),
  loading: Spinner
});

const NotFound = Loadable({
  loader: () => import('./containers/NotFound/NotFound'),
  loading: Spinner
});

type Props = {
  history: any
};

const Routes = ({ history }: Props): Node => {
  const ldClient = useLDClient();
  // Datalayer + Segment
  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    import(
      '../node_modules/@delta-defense/frontend-analytics/loadDataLayer/callLoadDataLayer.js'
    );
    const { REACT_APP_SEGMENT_WRITE_KEY, REACT_APP_SEGMENT_TRACK } =
      process.env;
    loadSegment(REACT_APP_SEGMENT_TRACK ? REACT_APP_SEGMENT_WRITE_KEY : '');

    const accessToken = jscookie.get('uscca_access');
    if (accessToken) {
      try {
        const encodedValue = accessToken.split('.')[1];
        const decodedValue = JSON.parse(window.atob(encodedValue));
        Analytics.Instance().identify(decodedValue.userId);
      } catch {
        // do nothing
      }
    }
  }, []);

  useEffect(() => {
    if (ldClient) {
      ldClient.identify(launchDarklyUser, null, () => undefined);
    }
  }, [ldClient]);

  useEffect(() => {
    Analytics.Instance().page();
  }, [history.location.pathname]);

  return (
    <ConnectedRouter history={history}>
      <Route>
        <DefaultLayout>
          <Switch>
            <Route exact path={NOT_FOUND_PATH} component={NotFound} />
            <Route
              exact
              path={`/${REGISTER_PATH}/:slug/:id`}
              component={Checkout}
            />
            <Redirect from="*" to="/404" />
          </Switch>
        </DefaultLayout>
      </Route>
    </ConnectedRouter>
  );
};

export default withLDProvider({
  clientSideID: launchDarklyId,
  user: launchDarklyUser
})(Routes);
