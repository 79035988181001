// @flow
import type { StateI as AppState } from '../reducer';
import type { EntityRecordI } from './EntityRecord';

export const entityOccurrenceIdSelector = (state: AppState): string =>
  state.entity.data.occurrenceId;

export const entityInstructorIdSelector = (state: AppState): string =>
  state.entity.data.instructorId;

export const entityDataSelector = (state: AppState): EntityRecordI =>
  state.entity.data;
